<template>
    <div class="wrapper-privacy">
        <cookies-popup />
        <navigation :isWhite="isWhiteNav" />
        <div class="content-page-wrapper">
            <div class="privacy-header">
                <h1>Privacy Policy</h1>
            </div>
            <div class="policy-list">
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.preface") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.prefaceText") }}</p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.obtainingInfo") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.obtainingInfoText") }}</p>
                        <p>{{ $t("privacyPolycy.obtainingInfoItem") }}</p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.personalInfo") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.personalInfoText") }}</p>
                        <p>{{ $t("privacyPolycy.personalInfoItem") }}</p>
                        <p>{{ $t("privacyPolycy.personalInfoItem2") }}</p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.managmentInfoSave") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.managmentInfoSaveText") }}</p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.outsourcingInfo") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.outsourcingInfoText") }}</p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.provisionInfo") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.provisionInfoText") }}</p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.correctionInfo") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.correctionInfoText") }}</p>
                        <p>{{ $t("privacyPolycy.correctionInfoText2") }}</p>
                        <p>{{ $t("privacyPolycy.correctionInfoText3") }}</p>
                        <p>{{ $t("privacyPolycy.correctionInfoText4") }}</p>
                        <p>{{ $t("privacyPolycy.correctionInfoText5") }}</p>
                        <p>{{ $t("privacyPolycy.correctionInfoName") }}</p>
                        <p>{{ $t("privacyPolycy.correctionInfoAddress") }}</p>
                        <p>{{ $t("privacyPolycy.correctionInfoEmail") }} <a class="a-common-gray" href="mailto:info@dnd.promo">info@dnd.promo</a></p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.otherPrecautions") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.otherPrecautionsText") }}</p>
                        <p>{{ $t("privacyPolycy.otherPrecautionsText2") }}</p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.ongoingImprovements") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.ongoingImprovementsText") }}</p>
                    </div>
                </div>
                <div class="rule-row">
                    <div class="header-rule">
                        <h2>{{ $t("privacyPolycy.changesPolycy") }}</h2>
                    </div>
                    <div class="body-rule">
                        <p>{{ $t("privacyPolycy.changesPolycyText") }}</p>
                        <p>{{ $t("privacyPolycy.changesPolycyText2") }}</p>
                    </div>
                </div>
            </div>
            <footerPage/>
        </div>
    </div>
</template>
<script>
import navigation from './../components/navigation'
import footerPage from './../sections/footer'
import cookiesPopup from './../components/cookies-popup'

export default {
    data() {
        return {
            isWhiteNav: false,
            mobileWidth: 793,
            tabletWidth: 992
        }
    },
    metaInfo: {
        title: "D&D Promotion - Privacy Policy"
    },
    components: {
        navigation, 
        footerPage,
        cookiesPopup
    },
    methods: {
        isMobile() {
            return this.getWindowWidth() <= this.mobileWidth;
        },
        isTablet() {
            return this.getWindowWidth() >= this.mobileWidth && this.getWindowWidth() <= this.tabletWidth;
        },
        getWindowWidth() {
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }
    },
    mounted() {
        this.isWhiteNav = this.isMobile() || this.isTablet();
    }
}
</script>
<style lang="scss" scoped>
@import '../scss/app';

.privacy-header {
    padding-top: 20vw;
    padding-bottom: 10vw;

    h1 {
        color: #191a1a;
        letter-spacing: 0.032em;
        line-height: 1.42em;
        font-size: 5vw;
        font-family: $family-bold;
        width: 70%;
        margin:auto;
    }
}

.policy-list {
    width: 70%;
    margin:auto;
    padding-bottom: 10vw;
}

.rule-row {
    padding-top: 5vw;
    display: flex;
}

.header-rule {
    width: 40%;
}

.header-rule h2 {
    color: #1D1F1F;
    font-size: 1.08vw;
    margin: 0 0 1em;
    position: relative;
    width: 100%;
    font-family: $family-bold;
 
}

.body-rule {
    width:60%;
    p {
        line-height: 1.56em;
        color: #313333;
        font-size: 1.08vw;
        margin-top: 1.5em;
        font-family: $family-regular;

        strong {
            color: #1D1F1F;
            font-family: $family-bold; 
        }

        &:first-child{
            margin-top: 0;
        }

        span {
            display: block;
        }
    }
}

@media (max-width: 992px) {
    .rule-row {
        flex-direction: column;
    }

    .header-rule, .body-rule {
        width:100%;

        p, h2 {
            @include font-size(15px);
            font-size: calc(15px + 2 * ((100vw - 375px) / 649));
        }
    }
}

@media (max-width: 576px) {
    .privacy-header{
        padding-top: 30vw;
        padding-bottom: 10vw;

        h1 {
            font-size: 36px;
            font-size: calc(36px + 16 * ((100vw - 375px) / 649));
            width: 78%;
        }
    }
    .policy-list {
        width: 78%;
    }
}
</style>